import styled from 'styled-components';

export const Col = styled.div`
    img {
        border-radius: 5px;
    }
`;

export const PromoBanner = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    gap:20px;

    @media (max-width: 991px) {
        flex-wrap: wrap;
        justify-content: center;

    }
`;
