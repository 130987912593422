import { memo } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Link from 'next/link';
import { useI18n } from '@src/locales';
import * as Styles from './promobanners.styled';

const shop = process.env.NEXT_PUBLIC_SHOP_NAME;

const PromoBanners = () => {
    const { locale } = useRouter();
    const { t } = useI18n();

    const siteLang = locale.replace('be', '');

    const promoLinks = {
        block1: {
            link: {
                office: {
                    benl: 'facilitair-en-werkplek/kantoormeubels', befr: 'services-generaux-poste-de-travail/mobilier-de-bureau',
                },
                gadgets: {
                    benl: 'tassen-en-drankflessen', befr: 'mugs-et-gourdes',
                },
            },
            title: {
                office: { benl: t('discover'), befr: t('discover') },
                gadgets: { benl: t('discover'), befr: t('discover') },
            },
        },
        block2: {
            link: {
                office: { benl: 'ict-en-digitaal', befr: 'ict-numerique' },
                gadgets: { benl: 'kantoorartikelen', befr: 'fournitures-de-bureau' },
            },
            title: {
                office: { benl: t('discover'), befr: t('discover') },
                gadgets: { benl: t('discover'), befr: t('discover') },
            },
        },
        block3: {
            link: {},
            title: {
                office: { benl: t('discover'), befr: t('discover') },
                gadgets: { benl: t('discover'), befr: t('discover') },
            },
        },
    };

    return (
        <Styles.PromoBanner>
            <Styles.Col>
                <Link href="#" passHref prefetch={false}>
                    <a>
                        <Image
                            src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}home/promobanner/${shop}/${siteLang || ''}/promobanner1${siteLang || ''}.webp`}
                            alt={promoLinks.block1.title[shop][locale]}
                            width={600}
                            height={185}
                        />
                    </a>
                </Link>
            </Styles.Col>
            <Styles.Col>
                <Link href="#" passHref prefetch={false}>
                    <a>
                        <Image
                            src={`${process.env.NEXT_PUBLIC_CDN_IMAGES}home/promobanner/${shop}/${siteLang || ''}/promobanner2${siteLang || ''}.webp`}
                            alt={promoLinks.block2.title[shop][locale]}
                            width={600}
                            height={185}
                        />
                    </a>
                </Link>
            </Styles.Col>
        </Styles.PromoBanner>
    );
};

export default memo(PromoBanners);
